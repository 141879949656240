var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row justify-content-start items-baseline w-100 mb-2",staticStyle:{"margin-bottom":"-5px"},attrs:{"id":"filter-card"}},[_c('span',{staticClass:"svg-icon back-button-color svg-icon-2x cursor-pointer mr-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('h3',{staticClass:"d-inline-block",attrs:{"id":"page-title"}},[_vm._v(_vm._s(_vm.$t("setting.notification")))])]),_c('dashboard-box',{scopedSlots:_vm._u([{key:"preview",fn:function(){return [_vm._l((_vm.notifications),function(value,index){return _c('div',{staticClass:"row px-10 py-1 cursor-pointer"},[_c('div',{staticClass:"d-flex w-100 items-center navy-blue-border-1px border-15px px-3 py-2 mb-4 relative",on:{"click":function($event){return _vm.onClickNotification(value)}}},[(value.readed != 1)?_c('span',{staticClass:"absolute bg-danger w-20px h-20px rounded-full text-center text-white",staticStyle:{"top":"0","right":"0","line-height":"20px","margin-top":"-7px","margin-right":"-7px"}},[_vm._v(" 1 ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.getIconByKey('icons.model_house.notifications', {
                  class: 'w-50px h-50px object-fill mr-3 d-line-block',
                })
              )}}),_c('div',{staticClass:"flex-grow-1 text-lg"},[_c('div',{staticClass:"text-xl"},[_vm._v(_vm._s(value["text"]))]),_c('div',{staticClass:"flex-grow-1 text-lg"},[_c('div',{staticClass:"text-xl"},[_vm._v(_vm._s(_vm.moment(value["created_at"], _vm.LARAVEL_DATE_FORMAT).format('DD.MM.YYYY HH:MM')))])])])])])}),(_vm.notifications.length < 1)?_c('div',{staticClass:"row px-10 py-1 cursor-pointer"},[_c('div',{staticClass:"d-flex w-100 items-center navy-blue-border-1px border-15px px-3 py-2 mb-4 relative",on:{"click":function($event){return _vm.$router.push({ name: _vm.value.route_name, params: _vm.value.params })}}},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.getIconByKey('icons.model_house.notifications', {
                  class: 'w-50px h-50px object-fill mr-3 d-line-block',
                })
              )}}),_c('div',{staticClass:"flex-grow-1 text-lg"},[_c('div',{staticClass:"text-xl"},[_vm._v(_vm._s(_vm.$t("general.no_notification")))])])])]):_vm._e()]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }